import { styled, Tabs } from 'react-ui-kit-exante';

export const TabsWrapper = styled('div')`
  background-color: ${({ theme }) => theme.color.bg.primary};
`;

export const TabsHeader = styled('div')`
  align-items: center;
  display: flex;
  padding: 24px 24px 12px 24px;
`;

export const TabsHeaderTitle = styled('div')`
  color: ${({ theme }) => theme.color.typo.primary};
  font-size: 32px;
  line-height: 48px;
  max-width: 960px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TabsContainer = styled(Tabs)`
  background-color: ${({ theme }) => theme.color.bg.primary};
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
`;
