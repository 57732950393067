import { useMemo } from 'react';

import { TTaskTableResponse } from '~/types/models';

import { getColumns } from '../columns';

export const useUpdates = (
  handleShowAbortTaskDialog: (id: number) => void,
  tasks: TTaskTableResponse[],
) => {
  const columns = useMemo(
    () => getColumns(handleShowAbortTaskDialog),
    [handleShowAbortTaskDialog],
  );

  const filteredTasks = useMemo(
    () => tasks.filter((task) => task.state === 'running'),
    [tasks],
  );

  return {
    columns,
    filteredTasks,
  };
};
