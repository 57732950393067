import { Tab, TabPanel } from 'react-ui-kit-exante';

import { DeleteDialog } from '~/components';

import { FinishedTasks } from './FinishedTasks';
import { Updates } from './Updates';
import { Tabs } from './constants';
import { useUpdatesQueue } from './hooks';
import {
  TabsContainer,
  TabsHeader,
  TabsHeaderTitle,
  TabsWrapper,
} from './styled';

export const UpdatesQueue = () => {
  const {
    handleAbortTask,
    handleClearAll,
    handleShowAbortTaskDialog,
    handleTabChange,
    handleUpdateAll,
    isDeleteDialogShown,
    isLoading,
    selectedTab,
    setIsDeleteDialogShown,
    tasks,
  } = useUpdatesQueue();

  return (
    <TabsWrapper>
      <TabsHeader>
        <TabsHeaderTitle>Updates Queue</TabsHeaderTitle>
      </TabsHeader>
      <TabsContainer value={selectedTab} onChange={handleTabChange}>
        <Tab label="Updates" />
        <Tab label="Finished Tasks" />
      </TabsContainer>
      <TabPanel value={selectedTab} index={Tabs.Updates}>
        <Updates
          handleShowAbortTaskDialog={handleShowAbortTaskDialog}
          handleUpdateAll={handleUpdateAll}
          isLoading={isLoading}
          tasks={tasks}
        />

        <DeleteDialog
          isShown={isDeleteDialogShown}
          isLoading={isLoading}
          onConfirm={handleAbortTask}
          onDecline={() => setIsDeleteDialogShown(false)}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={Tabs.FinishedTasks}>
        <FinishedTasks
          handleClearAll={handleClearAll}
          handleUpdateAll={handleUpdateAll}
          isLoading={isLoading}
          tasks={tasks}
        />
      </TabPanel>
    </TabsWrapper>
  );
};
