import { PropsWithChildren } from 'react';
import { Tabs } from 'react-ui-kit-exante';

import { TabRoutes } from './constants';
import { TabItem, TabsContainer, ChildrenContainer } from './styled';
import { SmartTabsProps } from './types';
import useSmartTabs from './useSmartTabs';

const SmartTabs = ({
  children,
  disabled,
}: PropsWithChildren<SmartTabsProps>) => {
  const { activeTab, hasError, handleTabChange } = useSmartTabs(disabled);

  return (
    <TabsContainer>
      <Tabs value={activeTab} onChange={handleTabChange}>
        {TabRoutes.map((tab) => (
          <TabItem
            disabled={disabled}
            error={hasError(tab)}
            key={tab.path}
            label={tab.label}
          />
        ))}
      </Tabs>
      <ChildrenContainer>{children}</ChildrenContainer>
    </TabsContainer>
  );
};

export default SmartTabs;
