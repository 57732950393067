import { useMemo } from 'react';

import { TTaskTableResponse } from '~/types/models';

import { getColumns } from '../columns';

export const useFinishedTasks = (tasks: TTaskTableResponse[]) => {
  const columns = useMemo(() => getColumns(), []);

  const filteredTasks = useMemo(
    () => tasks.filter((task) => task.state !== 'running'),
    [tasks],
  );

  return {
    columns,
    filteredTasks,
  };
};
